import { useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { collection, onSnapshot, where, query } from "firebase/firestore";
import db from './libs/FirebaseConf';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Statics from './pages/Statics';
import { dataContext } from './libs/Context';
import utils from './libs/Utilities';
import appData from './config';


function App() {
    const projectName = 'chc';
    const configRef = collection(db, 'config');
    const [stage, setStage] = useState('');
    const [checkId, setCheckId] = useState(null);
    const [collectionRef, setCollectionRef] = useState(collection(db, appData.collection));
    const [persons, setPersons] = useState([]);
    const q = query(configRef, where('projectName', '==', projectName));


    useEffect(() => {
        const unsub = onSnapshot(collectionRef, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            utils.sortNames(items);
            setPersons(items);
            // setDisplayPersons(items);
            // console.log(displayPersons);
        });
        return () => {
            unsub();
        }


    // eslint-disable-next-line
    }, [stage, collectionRef]);

    useEffect(() => {
      const unsubs = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
            if (doc.data().projectName === projectName) {
              console.log('Config stage: ' + doc.data().stage);
              setStage(doc.data().stage);
              setCollectionRef(collection(db, doc.data().collection));
              // console.log(collectionRef);
            }
        })
      });
      

      return () => {
          unsubs();
      }
    // eslint-disable-next-line
    }, []);

  return (
    <dataContext.Provider value={{persons, setPersons, collectionRef, checkId, setCheckId}}>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/statics' element={<Statics />} />
          <Route path='/dashboard' element={<Dashboard />} />
      </Routes>
      </dataContext.Provider>
  );
}

export default App;
