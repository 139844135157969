import { useState, useContext, useEffect } from "react"
import { dataContext } from '../libs/Context'
import { updateDoc, doc, serverTimestamp } from "firebase/firestore"

const Person = ({person}) => {
  const {collectionRef, checkId, setCheckId} = useContext(dataContext)
  const [checkable, setCheckable] = useState(false);

  useEffect(() => {
    if (checkId === person.id) {
      setCheckable(true)
    } else {
      setCheckable(false)
    }
  }, [checkId])
  // Update Name
  async function updateName(name, data) {
    try {
      const nameRef = doc(collectionRef, name.id);
      await updateDoc(nameRef, data);
      console.log('update name' + name.id + data)
    } catch(error) {
      console.error();
    }
  }

  function check() {
    const data = {
      checked: true,
      checkedAt: serverTimestamp()
    };
    updateName(person, data);
  }
  function setCheckStatus() {
    console.log('person checkable')
    setCheckId(person.id);
  }
  return (
    <div className={`person ${checkable ? "selected" : ""}`} onClick={setCheckStatus}>
        <h2 className={person.checked ? "checked" : "unchecked"}>{person.name}</h2>
        {checkable && <button onClick={check}>Check</button>}
    </div>
  )
}

Person.defaultProps = {

}

export default Person