import React from 'react'
import logo4 from '../images/logo-4.png'
import logo5 from '../images/logo-5.png'
import logo6 from '../images/logo-6.png'
import logo7 from '../images/logo-7.png'
import logo8 from '../images/logo-8.png'

function Footer() {
  return (
    <footer>
        <div className="logos">
            <img src={logo4} alt="" />
            <img src={logo5} alt="" />
            <img src={logo6} alt="" />
            <img src={logo7} alt="" />
            <img src={logo8} alt="" />
        </div>
        <div className="data">
            <p>Shahd Al Madinah - 6<sup>th</sup> January 2023</p>
        </div>
    </footer>
  )
}

export default Footer