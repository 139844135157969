import React, { useEffect, useContext } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CheckList from '../components/CheckList'
function Statics() {

  return (
    <div className={'container'}>
        <Header />
        <CheckList />
        <Footer />
    </div>
  )
}

export default Statics