import {useContext, useEffect, useState} from 'react'
import { dataContext } from '../libs/Context'
import Person from './Person';

function Check() {
  const {persons} = useContext(dataContext);
  const [displayPersons, setDisplayPersons] = useState([]);
  useEffect(() => {
    setDisplayPersons(persons.filter((name) => name.checked !== true));
    console.log(displayPersons);
    console.log(persons);
  }, [persons])

  const filterPersons = (e) => {
    setDisplayPersons(persons.filter((person) => person.name.toLowerCase().startsWith(e.target.value.toLowerCase()) && person.checked !== true));
  }
  return (
    <div className="check-section">
      <div className={"persons"}>
        {displayPersons.map((name, index) => (
          <Person key={name.id} person={name}/>
        ))}
      </div>
      <div className={"flex col search-container"}>
            <label htmlFor="" className="search-label">Search</label>
            <input type="text" className={"search"} onChange={filterPersons}/>
            <span className="search-icon"></span>
      </div>
    </div>
  )
}

export default Check