import React, { useEffect, useContext } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { dataContext } from '../libs/Context'
import Check from '../components/Check'
function Home() {

  return (
    <div className={'container'}>
        <Header />
        <div>
            <h1>Please check-in</h1>
        </div>
        <Check />
        <Footer />
    </div>
  )
}

export default Home