import React from 'react'
import logo1 from '../images/logo-1.png'
import logo2 from '../images/logo-2.png'
import logo3 from '../images/logo-3.png'

function Header() {
  return (
    <header>
        <div className="logos">
            <img src={logo1} alt="" />
            <img src={logo2} alt="" />
            <img src={logo3} alt="" />
        </div>
    </header>
  )
}

export default Header