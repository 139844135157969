

function ListPerson({person}) {
    // console.log(person);
    const timeConverter = (inputTime) => {
        var a = new Date(inputTime * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        // var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min ;
        return time;
      }


  return (
    <div className='person'>
      <div className='name'>
        <h2 className={person.checked ? "checked" : "unchecked"}>{person.name}</h2>     
      </div>
      <div className='status'>
        {person.checked ? "Yes" : "No"}
      </div>
      <div className='time'>
        {person.checkedAt ? timeConverter(person.checkedAt.seconds) : "-"}
      </div>
    </div>
  )
}

export default ListPerson