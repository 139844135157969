import {useContext, useEffect, useState} from 'react'
import ListPerson from './ListPerson'
import { dataContext } from '../libs/Context'

function CheckList() {
    const {persons} = useContext(dataContext);
    const [displayPersons, setDisplayPersons] = useState(persons);
    // console.log(persons);
    useEffect(() => {
        setDisplayPersons(persons);
    }, [persons]);

    const filterPersons = (e) => {
        setDisplayPersons(persons.filter((person) => person.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
    }
  return (
    <div className="check-section">
        <div className="head">
            <div className="name">Name</div>
            <div className="checked">Checked</div>
            <div className="time">Time</div>
        </div>
        <div className={"persons"}>
            {displayPersons.map((person) => (
                <ListPerson key={person.id} person={person}/>
            ))}       
        </div>
        <div className={"flex col search-container"}>
            <label htmlFor="" className="search-label">Search</label>
            <input type="text" className={"search"} onChange={filterPersons}/>
            <span className="search-icon"></span>
        </div>
    </div>
  )
}

export default CheckList