// import React, { useContext } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Settings from '../components/Settings'
// import { dataContext } from '../libs/Context'

function Dashboard() {
    // const data = useContext(dataContext);
  return (
    <div className={'container'}>
      <Header />
      <div>Dashboard</div>
      <Settings />
      <Footer />
    </div>
  )
}

export default Dashboard