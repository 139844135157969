import { uuidv4 } from '@firebase/util';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { useContext, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import { dataContext } from '../libs/Context'


function Settings() {
  const {persons, collectionRef} = useContext(dataContext)
  // console.log(collectionRef);
  useEffect(()=> {
    console.log('Settings effect')
    const input = document.getElementById('input')
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        console.log(rows.length)
        rows.forEach((row)=>{
          console.log(row[0]);
          addName(row[0]);
        })
      })
    })
    // eslint-disable-next-line
  }, [])

  // Add Name
  async function addName(name) {
    const newName = {
      id: uuidv4(),
      name: name,
      checked: false,
      checkedAt: ''
    }
    // console.log(collectionRef);
    try {
      const nameRef = doc(collectionRef, newName.id);
      await setDoc(nameRef, newName);
      console.log('Name Added')
    } catch(error) {
      console.error();
    }
  }
  // Delete Name
  const deleteName = async (name) => {
    try {
      const nameRef = doc(collectionRef, name.id);
      await deleteDoc(nameRef)
      console.log('delete name' + name.id)
    } catch(error) {
      console.error();
    }
  }
  // Update Name
  async function updateName(name, data) {
    try {
      const nameRef = doc(collectionRef, name.id);
      await updateDoc(nameRef, data);
      console.log('update name' + name.id + data)
    } catch(error) {
      console.error();
    }
  }

  const resetNamesCheckStatus = () => {
    console.log('Reseting names')
    const update = {
      checked: false,
      checkedAt: ''
    }
    persons.forEach((person) => {
      updateName(person, update)
    })
  }
  const resetNames = () => {
    console.log('Reseting names')
    persons.forEach((person) => {
      deleteName(person);
    })
  }

  function updateStatus(status) {

  }
  return (
    <div className="config">
      <div>
        <h3>Update Name Entries</h3>
        <input type="file" id="input" />
      </div>
      <div>
        <h3>Reset Database - Names</h3>
        <button onClick={resetNames}>Reset</button>
      </div>
      <div>
        <h3>Reset Database - Check-in</h3>
        <button onClick={resetNamesCheckStatus}>Reset</button>
      </div>
      <div>
        <h3>Set Database Mode</h3>
        <div>
        <button onClick={updateStatus('development')}>Development</button>
        <button onClick={updateStatus('testing')}>Testing</button>
        <button onClick={updateStatus('production')}>Production</button>
        </div>
      </div>
    </div>
  )
}

export default Settings